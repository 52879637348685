/* eslint-disable */
import React from 'react'
import Layout from '../components/Layout'
import Gettouch from '../components/Gettouch'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap'
import CardListing from '../components/BlogLising/CardsListing'
import HeroBlog from '../components/BlogLising/HeroBlog'
import BlogCategories from '../components/BlogLising/Categories'
import useFeaturedBlogPost from "../hooks/useFeaturedPost"


const BlogCategoryPage = props => {
  const cards = props.data.allContentfulBlogPost.edges[0].node.cardBlogListing
  const slug = props.data.allContentfulBlogPost.edges[0].node.slug
  const { currentPage, numPages, categoryList } = props?.pageContext
  const cardsData = props.data.allContentfulBlogPost.edges
  
  return (
    <Layout>
      <Helmet>
        <title>Urbian | Read</title>
      </Helmet>
      <Container className="mb-5 blog_post content-wrap">
        <BlogCategories categoryList={categoryList}></BlogCategories>
        <CardListing  cardsData={cardsData} slug={slug}></CardListing>
      </Container>
      <Gettouch></Gettouch>
    </Layout>
  )
}


export default BlogCategoryPage


export const contentfulBlogPageQuery = graphql`
  query blogCategoryPageWpQuery($skip: Int!, $limit: Int!,$category: String!,) {
    allContentfulBlogPost(
      skip: $skip, 
      limit: $limit, 
      sort: {order: DESC, fields: createdDate}, 
      filter: {categories: {eq: $category}}) {
      edges {
        node {
          slug
          pageTitle
          createdDate
          cardBlogListing {
            id
            titleCard
            imageCard {
              fluid(maxWidth: 800){
                ...GatsbyContentfulFluid_withWebp
              }
              id
            }
            descriptionCard {
              descriptionCard
              childMarkdownRemark {
                id
              }
            }
          }
          categories
        }
      }
    }
  }
`

